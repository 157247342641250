<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-bullhorn</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Notifications ({{ totalNotifications }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'notification.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Notification
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="notification-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs>
          <v-tab
            v-for="status in statusTabs"
            :key="status.value"
            @change="
              defaultStatus = status.value
              activeAction()
            "
          >
            {{ status.text }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search notification or building"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="notifications"
        :server-items-length="totalNotifications"
        :options.sync="options"
        :loading="loading"
        :v-model="selected"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetchNotifications"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
      >
        <template v-slot:item.is_active="{ item }">
          <v-checkbox v-model="item.is_active" readonly hide-details />
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
        <template v-slot:item.addedBy="{ item }">
          <div v-if="item.added_name" class="added-by-name">
            {{ item.added_name }}
          </div>
          <div v-if="item.added_email" class="added-by-email">
            {{ item.added_email }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small> mdi-delete </v-icon>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(notifications.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Notification list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import NOTIF_STATUSES from '@/utils/enums/NotifStatus'

export default {
  name: 'NotificationsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      statusTabs: NOTIF_STATUSES,
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'created_at',
      defaultStatus: 'active',
      sortOrder: false,
      filterBy: [
        {
          value: 'expires_at',
          label: 'Expiry date',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
      ],
      headers: [
        {
          text: 'Notification',
          value: 'shortNotification',
        },
        {
          text: 'Building',
          value: 'buildingName',
        },
        {
          text: 'Expiry date',
          value: 'expiryDate',
        },
        {
          text: 'Active',
          value: 'is_active',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
        {
          text: 'Added By',
          value: 'addedBy',
          sortable: false,
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
    }
  },

  created() {
    this.clearNotifications()
    this.fetchNotifications()
  },

  computed: {
    ...mapState({
      notifications: (state) => state.notification.list,
      listMeta: (state) => state.notification.listMeta,
      permissions: (state) => state.auth.permissions,
      totalNotifications: (state) => state.notification.totalNotifications,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.NOTIFICATIONS_CREATE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getNotifications: 'notification/getNotifications',
    }),

    ...mapMutations({
      clearNotifications: 'notification/clearNotificationList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterList(filterValue) {
      this.clearNotifications()
      this.fetchNotifications(null, filterValue)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchNotifications(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearNotifications()
      this.fetchNotifications()
    }, 600),

    async fetchNotifications(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalNotifications
            : options.itemsPerPage
          : 25,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        filter: {
          status: this.defaultStatus,
        },
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getNotifications(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'notification.details',
        params: { id: item.id },
      })
    },

    activeAction() {
      this.clearNotifications()
      this.fetchNotifications()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}

.added-by-email {
  font-size: 0.7em;
}
</style>

const NOTIF_STATUSES = [
  {
    text: 'Active',
    value: 'active',
  },
  {
    text: 'Past',
    value: 'expired',
  },
  // {
  //   text: 'All',
  //   value: 'all'
  // }
]

export default NOTIF_STATUSES
